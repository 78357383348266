import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    isConnected,
    getPublicKey,
    isAllowed,
    setAllowed,
    getNetwork
} from "@stellar/freighter-api";
import { checkUserExistsFirestore,   handleUserLogin } from './userModel';
import { auth, provider, signInWithPopup } from './firebaseConfig'; // Import auth and provider
import { getAuth,  } from 'firebase/auth';

export const useLoginOrRegister = () => {
    const navigate = useNavigate();
    const [isAuthenticating, setIsAuthenticating] = useState(false);

    const handleLoginOrRegister = useCallback(async () => {
        if (isAuthenticating) return; // Prevent multiple popup requests

        setIsAuthenticating(true);
 
            try {
                const auth = getAuth();
                const result = await signInWithPopup(auth, provider);
                const user = result.user;
        
                if (user) {
                    // After successful login, either create or update the user in Firestore
                    await handleUserLogin(user.uid);
                    navigate('/questions');
                  }
                
               
            } catch (error) {
                console.error("Authentication error: ", error);
                if (error.code === 'auth/popup-blocked') {
                    alert("Popup blocked. Please allow popups for this site and try again.");
                } else {
                    alert("Authentication failed. Please try again.", error);
                }
            } finally {
                setIsAuthenticating(false);
            }
         
    }, [navigate, isAuthenticating]);

    return handleLoginOrRegister;
};
