// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth, signInWithPopup, GoogleAuthProvider  } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAVB5LkyWTMOzzDSeKCTSp_SOj08Wqo7mU",
  authDomain: "joi-react-soroban.firebaseapp.com",
  projectId: "joi-react-soroban",
  storageBucket: "joi-react-soroban.appspot.com",
  messagingSenderId: "56278236485",
  appId: "1:56278236485:web:8dc47710de716090e6522c",
  measurementId: "G-LYCNRJ19SK"
}; 


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { app, analytics, db, auth, provider, signInWithPopup };